// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "@fontsource/ubuntu"
import "stylesheets/application"

import { install as installResizeObserver } from 'resize-observer'
installResizeObserver()

import { Chart, BarController, BarElement, PointElement, LinearScale, CategoryScale, Title } from "chart.js"
Chart.register(BarController, BarElement, PointElement, LinearScale, CategoryScale, Title)


function createChart(el) {
  const title    = el.getAttribute('data-chart-title')
  const labels   = JSON.parse(el.getAttribute('data-chart-labels'))
  const datasets = JSON.parse(el.getAttribute('data-chart-datasets'))

  datasets.forEach((set) => {
    set['backgroundColor'] = [
        'red',
        'yellow',
        'green',
        'blue'
    ]
  })

  const type = 'bar'
  const data = { labels, datasets }
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: { callback: (value) => value + "%" }
      }
    },
    plugins: {
      title: { display: title, text: title }
    }
  }
  console.log(data)
  new Chart(el, { type, data, options })
}

document.addEventListener('turbolinks:load', () => {
  const chartElements = Array.from(document.querySelectorAll('.js-chart'))
  chartElements.forEach(createChart)
})
